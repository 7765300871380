




























import { Component, Inject as VueInject, Prop, Vue } from 'vue-property-decorator'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'
import { ProductData } from '../../../../../contexts'

import { ProductCard } from '../../../../products/organisms/ProductCard'
import {
  translateProductToProductCard
} from '../../../../products/organisms/ProductCard/ProductCard.helpers'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<SuggestedProducts>({
  name: 'SuggestedProducts',
  components: {
    ProductCard
  }
})
export class SuggestedProducts extends Vue {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(EventbusType)
  public eventBus!: IEventbus

  @Prop({ type: Object, required: true })
  public readonly carouselConfig!: AnyObject

  @Prop({ type: Array, required: false })
  public readonly suggestedProducts?: Array<ProductData>

  /**
   * Products teaser content
   */
  public get products () {
    if (!this.suggestedProducts || this.suggestedProducts.length === 0) {
      return
    }

    return this.suggestedProducts.map(p => translateProductToProductCard(p))
  }
}

export default SuggestedProducts
